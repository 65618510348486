<template>
  <div>
    <div class="xs-p-20 xs-fill-white">
      <div class="form-section first-section">
        <div class="panel panel-default">
          <div class="panel-body t15">
            <h2 class="text-center bold text-red t15">{{$t('noti_enter_state')}}</h2>
            <div class="xs-fill-white pt-15">
              <div class=" first-section form-account-location">
                <div id="map" style="width: 0%; height: 0px; display: none"></div>
                <div class="form-field">
                  <div class="xs-flex xs-items-center xs-mb-5">
                    <label class="label xs-mb-0 text-black t15">{{$t('place_holder_name_location')}}<span class="cl-red">*</span> </label>
                    <span v-if="errors.locationType" class="xs-text-right typo-l7 xs-text-red xs-ml-auto ">
                                      <span>{{ errors.locationType }}</span>
                    </span>
                  </div>
                  <input type="text" ref="locationType" name="LocationType" v-bind:placeholder="$t('place_holder_name_location')" class="field input xs-mb-10 " id="locationType" v-model="locationType" />
                </div>
                <div class="form-field" v-if="isEdit">
                  <div class="xs-flex xs-items-center xs-mb-5">
                    <label class="label xs-mb-0 text-black t15">{{$t('place_holder_display_address')}}</label>
                  </div>
                  <input type="text" ref="locationType" name="LocationType" readonly="readonly" style="background: rgb(221, 221, 221);" class="field input xs-mb-10" id="displayAddress" v-model="displayAddress" />
                </div>
                <div class="info">
                  <!-- <div class="form-field">
                    <div class="xs-flex xs-items-center xs-mb-5">
                      <label class="label xs-mb-0 text-black t15 text-left" style="white-space: pre-wrap; line-height: 1.5em;">{{$t('localization.memo_search_by_zip')}}</label>
                    </div>
                    <div class="xs-flex xs-items-end xs-border xs-border-gray-light xs-border-1 rounded xs-relative xs-z-300 xs-fill-white xs-mb-10 xs-items-center " style="padding-right: 3px">
                      <div class="xs-flex-1">
                        <input class="search search--hut input xs-border-none xs-py-10 xs-px-10" id="zipcode_autocomplete" data-synth="zipcode-input" name="zipcode" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" placeholder="Ex. 1100005" value="" type="text" v-model="searchZipCode">
                      </div>
                      <div>
                        <button class="buttonload flex-1" v-if="loadingZip">
                          <i class="fa fa-refresh fa-spin"></i>
                        </button>
                        <button class="button button--primary flex-1" @click="searchByZipCode" style="min-height: 35px" v-else>
                          <div class="flex w-full flex-col  t14">{{$t('button_search')}}</div>
                        </button>
                      </div>
                    </div>
                    <div class="contain-outlet" v-bind:class="{'search-zip-code1': modeZipCode}" v-if="outletListByZipCode.length > 0">
                      <ul v-for="(outlet,index) in outletListByZipCode" :key="index" class="list-outlets">
                        <li class="outlet-item inactive text-black" @click="clickChosenOutlet(outlet)">
                          <p><span class="text-capitalize">{{outlet.kanji_address}}</span></p>
                        </li>
                      </ul>
                    </div>
                  </div> -->
                  <div class="form-field" v-if="!isEdit">
                    <div class="xs-flex xs-items-center xs-mb-5">
                      <label class="label xs-mb-0 text-black t15">{{$t('localization.search_by_address')}} </label>
                    </div>
                    <div class="xs-flex xs-items-end xs-border xs-border-gray-light xs-border-1 rounded xs-relative xs-z-300 xs-fill-white xs-mb-10 xs-items-center" style="padding-right: 3px">
                      <div class="xs-flex-1">
                        <input class="search search--hut input xs-border-none xs-py-10 xs-px-10" id="address-autocomplete" data-synth="address-input" name="address" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" v-bind:placeholder="$t('localization.placehover_search_by_address')" value="" type="text" v-model="searchAutoComplete">
                      </div>
                      <div>
                        <button class="buttonload flex-1" v-if="loadingFreeText">
                          <i class="fa fa-refresh fa-spin"></i>
                        </button>
                      </div>
                    </div>
                    <div class="contain-outlet">
                      <ul class="list-outlets" v-if="outletListByAddress.length > 0">
                        <li v-for="(outlet,index) in outletListByAddress" :key="index" class="outlet-item inactive text-black" @click="clickChosenAddress(outlet)">
                          <h4><span class="text-capitalize"><i class="fa fa-map-marker "  ></i> {{ convertString(outlet.address) }}</span></h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="form-field" v-if="!isEdit">
                    <div class="xs-flex xs-items-center xs-mb-5">
                      <label class="label xs-mb-0 text-black t15">{{$t('street')}}<span class="cl-red">*</span> </label>
                      <span v-if="errors.streetName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto ">
                                        <span>{{ errors.streetName }}</span>
                      </span>
                    </div>
                    <input type="text" ref="streetName" name="StreetName" v-bind:placeholder="$t('street')" class="field input xs-mb-10 " id="streetManual" v-model="streetName" readonly="readonly" style="background: #DDDDDD" />
                  </div>
                  <div class="form-field" v-if="!isEdit">
                    <div class="xs-flex xs-items-center xs-mb-5">
                      <label class="label xs-mb-0 text-black t15">{{$t('house_no')}}<span class="cl-red">*</span> </label>
                      <span v-if="errors.houseNo" class="xs-text-right typo-l7 xs-text-red xs-ml-auto ">
                                        <span>{{ errors.houseNo }}</span>
                      </span>
                    </div>
                    <input type="text" ref="houseNo" readonly="readonly" name="HouseNo" v-bind:placeholder="$t('place_holder_ex_house')" class="field input xs-mb-10 " id="houseNo" v-model="houseNo" style="background: #DDDDDD" />
                  </div>
                  <div class="checkbox-form">
                    <label class="fw400 cursor-pointer" for="make_default" style="line-height: 30px;">
                      <input id="make_default" class="checkbox" type="checkbox" name="make_default" value="1" v-model="makeDefault">
                      <i class="pull-left xs-my-5 border-radius-none-after"></i>
                      <span>  {{$t('make_default')}}</span> </label>
                  </div>
                </div>
                <button type="button" @click="save()" class="button button--primary save-location-address">{{$t('btm_save_changes')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'

var keApi = ''
var region = ''
var language = ''
export default {
  mounted () {
    keApi = process.env.VUE_APP_GOOGLE_MAP_API_KEY
    region = this.$const.COUNTRY_CODE
    language = this.$session.get('lang') || process.env.VUE_APP_DEFAULT_LANGUAGE
    this.loadScriptMap()
    if (this.$route.params.id) {
      this.getLocationInfoById(this.$route.params.id)
      this.isEdit = true
    }
  },
  data: function () {
    return {
      isEdit: false,
      isMode: 1,
      makeDefault: 0,
      lat: 0,
      long: 0,
      streetName: '',
      location: '',
      displayAddress: '',
      locationType: '',
      houseNo: '',
      landMark: '',
      description: '',
      zipCode: '',
      errors: {
        'streetName': '',
        'locationType': '',
        'houseNo': '',
        'landMark': ''
      },
      searchZipCode: '',
      outletListByZipCode: [],
      outletListByAddress: [],
      modeZipCode: false,
      loadingZip: false,
      outletinfo: {},
      searchAutoComplete: '',
      loadingFreeText: false,
      addressAutoComplete: false,
      searchLocation: false
    }
  },
  methods: {
    save: function () {
      this.errors = {
        'streetName': '',
        'houseNo': '',
        'locationType': '',
        'landMark': ''
      }
      let flag = false
      if (this.streetName === '') {
        this.errors.streetName = this.$t('validate_name_street')
        this.$refs.streetName.focus()
        flag = true
      }
      if (this.locationType === '') {
        this.errors.locationType = this.$t('validate_name_location')
        if (!flag) {
          this.$refs.locationType.focus()
        }
        flag = true
      }
      if (this.houseNo === '') {
        this.errors.houseNo = this.$t('validate_house')
        if (!flag) {
          this.$refs.houseNo.focus()
        }
        flag = true
      }

      if (flag) {
        return false
      }
      this.saveLocation()
      return true
    },
    loadScriptMap() {
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src =
          'https://maps.googleapis.com/maps/api/js?key=' +
          keApi +
          '&libraries=places&language=' +
          language +
          '&region=' +
          region
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'google-maps-jssdk')
    },
    /**
     * Search stores by zip code
     * */
    searchByZipCode () {
      this.mode = 'zip'
      this.addressList = []
      this.outletListByZipCode = []
      if (!this.searchZipCode) {
        return false
      }
      if (this.searchZipCode.length != 7) {
        this.$notify({
          type: 'danger',
          title: this.$t('message_invalid_postcode')
        })
        return false
      }
      var vm = this
      this.loadingZip = true
      vm.message = ''
      this.axios.apiOutlet.getOutletByAddressCode({ zip_code: this.searchZipCode }, function (response) {
        vm.loadingZip = false
        if (response.data.data.items.length <= 0) {
          vm.message = vm.$t('localization.stores_404')
        } else {
          // vm.$router.push({ 'name': 'test-localization', params: { zip: vm.searchZipCode } })
          vm.outletListByZipCode = response.data.data.items
        }
      }, function (error) {
        vm.loadingZip = false
        vm.message = vm.$t('localization.stores_404')
      })
    },
    parseAddressComponents(addressComponents) {
      if (!addressComponents) return undefined
      if (_.isArray(addressComponents)) {
        // Google GEOCODING Implementation
        var numberComponent = addressComponents.filter(ac =>
          ac.types.includes('street_number')
        )[0]
        var streetComponent = addressComponents.filter(ac =>
          ac.types.includes('route')
        )[0]
        var neighborhoodComponent = addressComponents.filter(
          ac =>
            ac.types.includes('sublocality_level_1') ||
            ac.types.includes('neighborhood')
        )[0]
        var cityComponent = addressComponents.filter(ac =>
          ac.types.includes('locality')
        )[0]
        var stateComponent = addressComponents.filter(ac =>
          ac.types.includes('administrative_area_level_1')
        )[0]
        var countryComponent = addressComponents.filter(ac =>
          ac.types.includes('country')
        )[0]
        var postalCodeComponent = addressComponents.filter(ac =>
          ac.types.includes('postal_code')
        )[0]
        return {
          number: numberComponent ? numberComponent.long_name : null,
          street: streetComponent ? streetComponent.long_name : null,
          neighborhood: neighborhoodComponent
            ? neighborhoodComponent.long_name
            : null,
          city: cityComponent ? cityComponent.long_name : null,
          state: stateComponent ? stateComponent.long_name : null,
          country: countryComponent ? countryComponent.long_name : null,
          zip_code: postalCodeComponent ? postalCodeComponent.long_name : null,
        }
      } else { return undefined }
    },
    /**
     * Search stores by free text
     * */
    searchByAddress () {
      var ref = this
      this.addressList = []
      this.outletListByAddress = []
      if (!this.searchAutoComplete) {
        return false
      }
      this.mode = 'free'
      // this.locationCenter = {lat: 0, lng: 0}
      this.loadingFreeText = true
      ref.message = ''
      this.axios.queryGoogleGeocoding(
        this.searchAutoComplete,
        function(response) {
          ref.loadingFreeText = false
          if (response && response[0]) {
            const {
              street,
              number,
              zip_code
            } = ref.parseAddressComponents(response[0].address_components)
            const location = response[0].formatted_address
            ref.streetName = street || ''
            ref.houseNo = number || ''
            ref.location = location || ''
            ref.zipCode = zip_code || ''
          }
        },
        function(data) {
          console.warn('Addresss not found')
        }
      )
      // this.axios.apiOutlet.getNextLevelData({address: this.searchAutoComplete, order_type: 'D', limit: 10, client_id: 1}, function (response) {
      //   ref.outletListByAddress = response.data.data.items
      //   if (ref.addressList.length <= 0) {
      //     ref.message = ref.$t('localization.stores_404')
      //   }
      //   ref.loadingFreeText = false
      // }, function (error) {
      //   console.log(`error: ${error}`)
      //   ref.message = ref.$t('localization.stores_404')
      //   ref.loadingFreeText = false
      // })
    },
    clickChosenOutlet (outlet) {
      this.streetName = outlet.kanji_address
      this.outletinfo.location = outlet.kanji_address
      this.outletinfo.location_code = outlet.address_code
      this.outletinfo.lat = outlet.lat
      this.outletinfo.long = outlet.long
      this.outletListByZipCode = []
    },
    clickChosenAddress (outlet) {
      let vm = this
      this.streetName = outlet.address
      this.axios.apiOutlet.getOutletByAddressCode({ address_code: outlet.address_code },
        function (response) {
          let data = response.data.data.items[0]
          vm.outletinfo.location = data.address
          vm.outletinfo.location_code = data.address_code
          vm.outletinfo.lat = data.lat
          vm.outletinfo.long = data.long
        })
      this.outletListByAddress = []
    },
    saveLocation () {
      let vm = this
      this.outletinfo.customer_id = this.$auth.user().id
      this.outletinfo.house_no = this.houseNo
      this.outletinfo.name = this.locationType
      this.outletinfo.landmark = this.streetName
      this.outletinfo.location = this.location,
      this.outletinfo.zip_code = this.zipCode,
      this.outletinfo.instruction = this.description
      this.outletinfo.default_location = this.makeDefault
      this.outletinfo.status = 1
      this.outletinfo.token = this.$auth.user().token
      if (this.$route.params.id) {
        this.outletinfo.id = this.$route.params.id
        this.axios.apiCustomer.editLocation(vm.outletinfo, (response) => {   
          let info = vm.$auth.user()
          for (let i in info.locations) {
            if (info.locations[i].id === vm.$route.params.id) { info.locations[i] = response.data.data.item } else if (response.data.data.item.default_location == 1) { info.locations[i].default_location = 0 }
          }
          vm.$auth.token('default_auth_token', btoa(escape(JSON.stringify(info))))
          vm.$auth.user(info)
          vm.$router.push({ 'name': 'profile' })
          vm.$notify({
            type: 'success',
            title: vm.$t('noti_title_update_success')
          })
          vm.$router.push({ 'name': 'profile' })
        })
      } else {
        this.axios.apiCustomer.createLocation(vm.outletinfo, (response) => {
          let info = vm.$auth.user()
          for (let i in info.locations) {
            if (response.data.data.item.default_location === 1) { info.locations[i].default_location = 0 }
          }
          info.locations.push(response.data.data.item)
          vm.$auth.token('default_auth_token', btoa(escape(JSON.stringify(info))))
          vm.$auth.user(info)
          vm.$notify({
            type: 'success',
            title: vm.$t('noti_title_create_success')
          })
          vm.$router.push({ 'name': 'profile' })
        })
      }
    },
    getLocationInfoById (id) {
      let vm = this
      this.axios.apiCustomer.getLocation(id, (response) => {
        let data = response.data.data.item
        vm.houseNo = data.house_no
        vm.displayAddress = data.display_address
        vm.locationType = data.name || data.location
        vm.streetName = data.location || data.landmark
        vm.landMark = data.landmark
        vm.description = data.instruction
        vm.makeDefault = data.default_location
        vm.outletinfo.location = data.location
        vm.outletinfo.location_code = data.location_code
        vm.outletinfo.lat = data.lat
        vm.outletinfo.long = data.long
      })
    }
  },
  watch: {
    streetName: function (newValue, oldValue) {
      this.errors.streetName = false
      if (!this.streetName) {
        this.errors.streetName = this.$t('validate_name_street')
      }
    },
    houseNo: function (newValue, oldValue) {
      this.errors.houseNo = false
      if (!this.houseNo) {
        this.errors.houseNo = this.$t('validate_house')
      }
    },
    landMark: function (newValue, oldValue) {
      this.errors.landMark = false
      if (!this.landMark) {
        this.errors.landMark = 'LandMark required.'
      }
    },
    locationType: function (newValue, oldValue) {
      this.errors.locationType = false
      if (!this.locationType) {
        this.errors.locationType = this.$t('validate_name_location')
      }
    },
    searchAutoComplete: _.debounce(function (newValue, oldValue) {
      if (!this.searchLocation) {
        this.searchByAddress()
      }
    }, 200)
  }
}

</script>
<style scoped>
.form-section {
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

</style>
